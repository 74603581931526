import { useLazyQuery, useMutation } from "@apollo/react-hooks";
import {
  Button,
  createStyles,
  FormControlLabel,
  Grid,
  makeStyles,
  Paper,
  Switch,
  TextField,
  Theme,
} from "@material-ui/core";
import SaveIcon from "@material-ui/icons/Save";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { DateTime } from "luxon";
import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { GET_BUYERS } from "../../../common/models/buyers";
import { GET_CONTRACTS } from "../../../common/models/contracts";
import { SAVE_DEACTIVATION_SCHEDULE } from "../../../common/models/deactivationSchedule";
import {
  GetBuyers,
  GetBuyers_LDPConfigQueryGroup_Buyer,
} from "../../../common/models/types/getBuyers";
import {
  GetContracts,
  GetContracts_LDPConfigQueryGroup_Contract,
} from "../../../common/models/types/GetContracts";
import { GetDeactivationSchedule_LDPConfigQueryGroup_GetDeactivationSchedule } from "../../../common/models/types/GetDeactivationSchedule";
import {
  GetSubVertical,
  GetSubVertical_LDPConfigQueryGroup_SubVertical,
} from "../../../common/models/types/GetSubVertical";
import {
  GetVertical,
  GetVertical_LDPConfigQueryGroup_Vertical,
} from "../../../common/models/types/GetVertical";
import { GET_SUBVERTICAL, GET_VERTICAL } from "../../../common/models/vertical";
import {
  getTimezoneSettingLongDesc,
  usTimezones,
} from "../../../common/utils/date";
import { DeactivationScheduleInputType } from "../../../types/graphql-global-types";

interface DeactivationScheduleFormModalProps {
  data:
    | any
    | GetDeactivationSchedule_LDPConfigQueryGroup_GetDeactivationSchedule;
  action: string;
  close: Function;
  refetch: Function;
  dispatch: Function;
}

interface Option {
  id: number;
  label: string | null;
}

const WrappedDeactivationScheduleFormModal = ({
  action,
  data,
  close,
  refetch,
  dispatch,
}: DeactivationScheduleFormModalProps) => {
  const classes = useStyles();
  const pageTitle =
    action == "create" ? "Add New Buyer Group" : "Edit Buyer Group";

  const [getBuyers, { data: buyerData }] = useLazyQuery<GetBuyers>(GET_BUYERS);
  const [buyerOptions, setBuyerOptions] = useState<Array<Option>>([]);
  const [buyer, setBuyer] = useState<any>(null);

  const [getContracts, { data: contractData }] = useLazyQuery<GetContracts>(
    GET_CONTRACTS
  );
  const [contractOptions, setContractOptions] = useState<Array<Option>>([]);
  const [contracts, setContracts] = useState<Array<Option>>([]);

  const [getVerticals, { data: verticalData }] = useLazyQuery<GetVertical>(
    GET_VERTICAL
  );
  const [verticalOptions, setVerticalOptions] = useState([]);
  const [vertical, setVertical] = useState<any>(null);

  const [
    getSubverticals,
    { data: subVerticalData },
  ] = useLazyQuery<GetSubVertical>(GET_SUBVERTICAL);
  const [subVerticalOptions, setSubVerticalOptions] = useState([]);
  const [subVertical, setSubVertical] = useState<any>(null);

  const timezoneOptions = Object.entries(usTimezones).map(
    ([key, value]) => value
  );
  const [timezone, setTimezone] = useState<any>(
    timezoneOptions.find((it: any) => it === data?.Timezone)
  );

  useEffect(() => {
    if (contractData) {
      const contractList = contractData?.LDPConfigQueryGroup?.Contract?.map(
        (item: GetContracts_LDPConfigQueryGroup_Contract | null) => ({
          id: item?.ContractId,
          label: `${item?.ContractId}: ${item?.ContractName}`,
        })
      );

      setContractOptions(contractList || []);

      if (data) {
        const selectedContracts = data?.ContractIdList?.split(",");
        if (selectedContracts) {
          setContracts(
            contractList?.filter((it: Option) =>
              selectedContracts.includes(it.id?.toString())
            ) || []
          );
        }
      }
    } else getContracts();

    if (buyerData) {
      const buyerList: any = buyerData?.LDPConfigQueryGroup?.Buyer?.map(
        (item: GetBuyers_LDPConfigQueryGroup_Buyer | null) => ({
          id: item?.BuyerId,
          label: item?.BuyerName,
        })
      );
      setBuyerOptions(buyerList || []);

      if (data)
        setBuyer(buyerList?.find((it: Option) => it.id === data.BuyerId));
    } else getBuyers();

    if (verticalData) {
      const verticalList: any = verticalData?.LDPConfigQueryGroup?.Vertical?.map(
        (item: GetVertical_LDPConfigQueryGroup_Vertical | null) => ({
          id: item?.VerticalId,
          label: item?.VerticalName,
        })
      );
      setVerticalOptions(verticalList);

      if (data)
        setVertical(
          verticalList?.find((it: Option) => it.label === data.VerticalName)
        );
    } else getVerticals();

    if (!subVerticalData) getSubverticals();
  }, [contractData, buyerData, verticalData, subVerticalData]);

  // render vertical options
  useEffect(() => {
    setVertical(
      verticalOptions?.find((it: any) => it?.label === data?.VerticalName) ||
        null
    );
  }, [verticalOptions]);

  // render sub-vertical options based on vertical selected value
  useEffect(() => {
    if (vertical) {
      const subVerticalList = subVerticalData?.LDPConfigQueryGroup?.SubVertical?.map(
        (item: GetSubVertical_LDPConfigQueryGroup_SubVertical | null) => ({
          id: item?.SubVerticalId,
          label: item?.SubVerticalName,
          parent: item?.VerticalId,
        })
      );

      const options: any =
        subVerticalList?.filter((it: any) => it?.parent === vertical.id) ?? [];
      setSubVerticalOptions(options);
      setSubVertical(
        options?.find((it: any) => it?.label === data?.SubVerticalName)
      );
    }
  }, [vertical]);

  useEffect(() => {
    if (contracts.length > 0) {
      setVertical(null);
      setSubVertical(null);
    }
  }, [contracts]);

  const [saveDeactivationSchedule] = useMutation(SAVE_DEACTIVATION_SCHEDULE);
  const {
    register,
    handleSubmit,
    errors,
  } = useForm<DeactivationScheduleInputType>();

  const [disableButtons, setDisableButtons] = useState<boolean>(false);

  const onSubmit = (input: DeactivationScheduleInputType) => {
    // input.DeactivationScheduleId = input.DeactivationScheduleId;
    input.BuyerId = buyer?.id || null;
    input.ContractIdList = contracts?.map((it: any) => it.id).join(",") || null;
    input.Timezone = timezone || getTimezoneSettingLongDesc();
    input.VerticalName = input.VerticalName || null;
    input.SubVerticalName = input.SubVerticalName || null;
    input.Name = input.Name || null;
    input.StartDate = DateTime.fromJSDate(startDate).toFormat(`yyyy-MM-dd'T'HH:mm:ss`)
    input.EndDate = DateTime.fromJSDate(endDate).toFormat(`yyyy-MM-dd'T'HH:mm:ss`)

    if (input.BuyerId === null && input.ContractIdList === null) {
      return toast.error(
        "Please enter any of the Buyer Name or Contracts to proceed."
      );
    }

    if (input.BuyerId !== null && input.ContractIdList !== null) {
      return toast.error(
        "Please enter only 1 field either Buyer Name or Contracts to proceed."
      );
    }

    (input.UserId = ""), setDisableButtons(true);
    saveDeactivationSchedule({
      variables: { input },
    })
      .then((response: any) => {
        toast.success(
          `Deactivation Schedule ${
            Number(input?.DeactivationScheduleId ?? 0) > 0 ? "updated" : "created"
          } successfully.`
        );
        setDisableButtons(false);
        refetch();
        //dispatch(signalBuyerGroupSaved());
        close();
      })
      .catch(({ errors }) => {
        setDisableButtons(false);
        if (errors) toast.error("Unexpected error!");
      });
  };

  const Constraints = {
    BuyerName: {
      required: {
        value: true,
        message: "Buyer Name is required.",
      },
    },
    ContractIdList: {
      required: {
        value: true,
        message: "Contracts is required.",
      },
    },
    Timezone: {
      required: {
        value: true,
        message: "Timezone is required.",
      },
    },
  };

  /** use for displaying dates on components that doesnt support moment objects */
  const [startDate, setStartDate] = useState<Date>(
    data?.StartDate
      ? DateTime.fromISO(data?.StartDate)?.toJSDate()
      : DateTime.local().toJSDate()
  );
  const [endDate, setEndDate] = useState<Date>(
    data?.EndDate
      ? DateTime.fromISO(data?.EndDate)?.toJSDate()
      : DateTime.local().toJSDate()
  );

  const filterOptions = (options: any[], { inputValue }: any) => {
    return options.filter(
      (option: { id: { toString: () => string | any[] } }) =>
        option.id.toString().includes(inputValue)
    );
  };

  return (
    <Paper className={classes.contrainer}>
      <form className={classes.root} onSubmit={handleSubmit(onSubmit)}>
        <Grid className={classes.mainGrid} container spacing={2}>
          <Grid item xs={12}>
            <TextField
              required
              hidden
              inputRef={register}
              name="DeactivationScheduleId"
              defaultValue={data?.DeactivationScheduleId || 0}
            />
            <TextField
              required
              hidden
              inputRef={register}
              name="UserId"
              defaultValue={data?.UserId ?? "Test User"}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              inputRef={register}
              name="Name"
              label="Deactivation Name"
              defaultValue={data?.Name ?? ""}
              variant="outlined"
              autoComplete="off"
            />
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              id="buyer-id-input"
              options={buyerOptions}
              value={buyer || ""}
              getOptionLabel={(option) => option.label}
              onChange={(event: any, newValue: any) => {
                setBuyer(newValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  inputRef={register}
                  name="BuyerId"
                  label="Buyer Name"
                  variant="outlined"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <Autocomplete
              multiple
              filterSelectedOptions
              id="contracts-id-list"
              options={contractOptions || []}
              getOptionLabel={(option) => option.label}
              getOptionSelected={(option, value) => option.id === value.id}
              filterOptions={filterOptions}
              value={contracts || []}
              onChange={(event: any, newValue: any) => {
                setContracts(newValue);
              }}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" label="Contracts" />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Autocomplete
              id="vertical-id-input"
              options={verticalOptions}
              value={vertical}
              disabled={contracts.length > 0}
              getOptionLabel={(option) => option.label}
              onChange={(event: any, newValue: any) => {
                setVertical(newValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  inputRef={register}
                  name="VerticalName"
                  label="Vertical Name"
                  variant="outlined"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Autocomplete
              id="subvertical-id-input"
              options={subVerticalOptions}
              value={subVertical}
              getOptionLabel={(option) => option.label}
              onChange={(event: any, newValue: any) => {
                setSubVertical(newValue);
              }}
              disabled={
                contracts.length > 0 || (!vertical && contracts.length < 1)
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  inputRef={register}
                  name="SubVerticalName"
                  label="Subvertical Name"
                  variant="outlined"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <DatePicker
              id="start-date-input"
              name="StartDate"
              /* className={classes.input} */
              dateFormat={"yyyy-MM-dd hh:mm a"}
              onChange={(date) => {
                (date instanceof Date) && setStartDate(date);
              }}
              showTimeSelect
              selected={startDate}
              customInput={
                <TextField
                  label={`Start Date`}
                  variant="outlined"
                  inputRef={register()}
                  name="StartDate"
                  fullWidth
                />
              }
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <DatePicker
              id="end-date-input"
              name="EndDate"
              /* className={classes.input} */
              dateFormat={"yyyy-MM-dd hh:mm a"}
              onChange={(date) => {
                (date instanceof Date) && setEndDate(date);
              }}
              showTimeSelect
              allowSameDay
              selected={endDate}
              customInput={
                <TextField
                  error={errors.EndDate ? true : false}
                  helperText={errors.EndDate && errors.EndDate?.message}
                  label={`End Date`}
                  variant="outlined"
                  inputRef={register({
                    validate: (value) => {
                      if (endDate.getTime() < startDate.getTime()) {
                        return `End Date is not greater than start date`;
                      }
                    },
                  })}
                  name="EndDate"
                  fullWidth
                />
              }
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            {/* <TextField
              inputRef={register}
              name="Timezone"
              label="Timezone"              
              defaultValue={data?.Timezone ?? "Pacific Standard Time"}
              variant="outlined"
            /> */}
            <Autocomplete
              id="timezone-id-input"
              options={timezoneOptions}
              value={timezone}
              onChange={(event: any, newValue: any) => {
                setTimezone(newValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={errors.Timezone ? true : false}
                  helperText={errors.Timezone && errors.Timezone?.message}
                  inputRef={register(Constraints.Timezone)}
                  name="Timezone"
                  label="Timezone"
                  variant="outlined"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControlLabel
              control={
                <Switch
                  inputRef={register}
                  defaultChecked={data?.IsActive ?? false}
                  name="IsActive"
                  color="primary"
                />
              }
              label="Active"
            />
          </Grid>
          {/* <Grid item xs={12}>
            <TextField
              inputRef={register}
              name="OverrideExclusiveSale"
              label="Override Exclusive Sale"
              defaultValue={data?.OverrideExclusiveSale ?? 0}
              variant="outlined"
            />
          </Grid> */}
          <Grid item xs={6}>
            <Button
              disabled={disableButtons}
              variant="contained"
              type="button"
              size="large"
              fullWidth
              onClick={() => close()}
            >
              Cancel
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              disabled={disableButtons}
              variant="contained"
              color="primary"
              type="submit"
              size="large"
              fullWidth
              startIcon={<SaveIcon />}
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </form>
    </Paper>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    contrainer: {
      textAlign: "left",
    },
    mainGrid: {
      padding: "20px",
    },
    pagetitle: {
      padding: "20px",
      color: "white",
      background: "#457373",
    },
    root: {
      "& .MuiTextField-root": {
        width: "100%",
      },
    },
  })
);

export const DeactivationScheduleFormModal = connect()(
  WrappedDeactivationScheduleFormModal
);
